// Gifs
const PRELOAD_GIF = require('../assets/preload.gif');
const DEFAULT_UPLOADING_GIF = require('../assets/default_theme_upload.gif');
const MY_TEAM_INSIGHTS_THEME_UPLOADING_GIF = require('../assets/my_team_insights_theme_upload.gif');
const LOADING_GIF = require('../assets/load-green.gif');
// Icons
const BREAKFAST_ICON = require('../assets/breakfast.png');
const LUNCH_ICON = require('../assets/lunch.png');
const SNACK_ICON = require('../assets/snack.png');
const DINNER_ICON = require('../assets/dinner.png');
const CHECK_ICON = require('../assets/check.png');
const ERROR_ICON = require('../assets/error-icon.png');
const CONNECTION_ICON = require('../assets/no-connection.png');
const DEFAULT_THEME_FAV_ICON = require('../assets/default_theme_fav_icon.png');
const MY_TEAM_INSIGHTS_THEME_FAV_ICON = require('../assets/my_team_insights_theme_fav_icon.png');
// Logos
const DEFAULT_THEME_LOGO = require('../assets/default_theme_logo.png');
const MY_TEAM_INSIGHTS_THEME_LOGO = require('../assets/my_team_insights_theme_logo.png');
// Others
const ERROR_IMAGE = require('../assets/error.png');
const DEFAULT_THEME_THANKS_IMAGE = require('../assets/default_theme_thanks.png');
const MY_TEAM_INSIGHTS_THEME_THANKS_IMAGE = require('../assets/my_team_insights_theme_thanks.png');

export {
  BREAKFAST_ICON,
  CHECK_ICON,
  CONNECTION_ICON,
  DINNER_ICON,
  ERROR_ICON,
  ERROR_IMAGE,
  LOADING_GIF,
  LUNCH_ICON,
  DEFAULT_THEME_LOGO,
  MY_TEAM_INSIGHTS_THEME_LOGO,
  PRELOAD_GIF,
  SNACK_ICON,
  DEFAULT_THEME_THANKS_IMAGE,
  MY_TEAM_INSIGHTS_THEME_THANKS_IMAGE,
  DEFAULT_UPLOADING_GIF,
  MY_TEAM_INSIGHTS_THEME_UPLOADING_GIF,
  DEFAULT_THEME_FAV_ICON,
  MY_TEAM_INSIGHTS_THEME_FAV_ICON,
};
