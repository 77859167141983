import React, { useCallback } from 'react';
import './languageSelector.css';
import { SUPPORTED_LANGUAGES } from '../../locales';
import { useTranslation } from 'react-i18next';
import * as actions from '../../store/actions/rootActions';
import { useDispatch } from 'react-redux';

const { ENGLISH, SPANISH } = SUPPORTED_LANGUAGES;

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const isEnglishLanguageSelected = i18n.language === ENGLISH;
  const dispatch = useDispatch();

  const handleLanguageToggle = useCallback(() => {
    const language = i18n.language === ENGLISH ? SPANISH : ENGLISH;
    i18n.changeLanguage(language);
    dispatch(actions.getSurvey());
  }, [i18n]);

  return (
    <div className="language-selector-container">
      <span className="language-selector-label">Español</span>

      <label className="toggle-switch">
        <input
          type="checkbox"
          checked={isEnglishLanguageSelected}
          onChange={handleLanguageToggle}
        />
        <span className="slider"></span>
      </label>

      <span className="language-selector-label">English</span>
    </div>
  );
};

export default LanguageSelector;
