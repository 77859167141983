import { all, put, select, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions/rootActions';
import * as actionTypes from '../actions/actionTypes';
import { browserHistory } from '../../index';
import ApiService from '../../services/apiService';
import i18n from '../../react-i18next';
import { SUPPORTED_LANGUAGES } from '../../locales';

function* getSurveySaga() {
  try {
    const token = yield select((state) => state.auth.token);
    const language =
      i18n.language === SUPPORTED_LANGUAGES.ENGLISH ? 'en-US' : 'es-US';
    if (token) {
      const response = yield ApiService.get('survey', {
        headers: {
          Authorization: token,
          'Accept-Language': language,
        },
      }).catch((error) => console.log(error));
      if (response) {
        const survey = { ...response.data };
        yield put(actions.setThemeData(survey.theme_type));

        survey.questions = survey.questions.map((question) => {
          return {
            question_id: question.id,
            name: question.name,
            comment: '',
            ranking: '',
            photos: [],
          };
        });
        survey.survey_id = null;
        yield put(actions.saveSurvey(survey));
        yield put(actions.setIsLoadingLogin(false));
      }
    } else {
      browserHistory.push('/error');
    }
  } catch (error) {
    console.log('Error: ', error);
    browserHistory.push('/error');
  }
}

export default function* surveySaga() {
  yield all([takeLatest(actionTypes.GET_SURVEY, getSurveySaga)]);
}
